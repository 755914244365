import { ProfileOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as roles from '../../services/constants/roles';
import {
  ADMIN_ROLE_ID,
  DISTRICT_ROLE_ID,
  SCHOOL_ROLE_ID,
} from '../../services/constants/roles';
import { fetchSettings, getSetting } from '../../store/reducers/settingSlice';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

function Dashboard(props) {
  const navigate = useNavigate();
  const [districts, setDistricts] = useState(0);
  const [schools, setSchools] = useState(0);
  const [products, setProducts] = useState(0);
  const [orders, setOrders] = useState(0);
  const [orderGuides, setOrderGuides] = useState(0);
  const setting = useSelector((state) => state.setting.settings);
  const newsValue = useSelector((state) => state.setting.value);
  const dispatch = useDispatch();
  const state = localStorage.getItem('state');
  const user = JSON.parse(state)?.user;
  // let storageProductName = `filterData-products-${user?.id}`.replace('/', '');
  // let storageOrderName = `filterData-orders-${user?.id}`.replace('/', '');
  // let storageUsersName = `filterData-users-${user?.id}`.replace('/', '');
  // let storageOrderGuidesName = `pageData-order-guides-${user?.id}`.replace(
  //   '/',
  //   '',
  // );
  // let storageFilterOrderGuidesName =
  //   `filterData-order-guides-${user?.id}`.replace('/', '');
  // let storageUsersSchoolName = `filterData-users/schools-${user?.id}`;

  // useEffect(() => {
  //   localStorage.removeItem(storageProductName);
  //   localStorage.removeItem(storageOrderName);
  //   localStorage.removeItem(storageUsersName);
  //   localStorage.removeItem(storageUsersSchoolName);
  //   localStorage.removeItem(storageOrderGuidesName);
  //   localStorage.removeItem(storageFilterOrderGuidesName);
  // }, []);

  useEffect(() => {
    if (!user?.id) return;

    const userId = user.id;

    Object.keys(localStorage).forEach((key) => {
      if (key.includes(userId)) {
        localStorage.removeItem(key);
      }
    });
  }, [user?.id]);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getSetting('news'));
  }, [setting]);
  useEffect(() => {
    props.setSelectedMenu('1');
    axios
      .get(`/users/count/?&role.id=${DISTRICT_ROLE_ID}&deleted=false&`)
      .then((res) => {
        setDistricts(res.data);
      });

    axios
      .get(`/users/count/?&role.id=${SCHOOL_ROLE_ID}&deleted=false&`)
      .then((res) => {
        setSchools(res.data);
      });

    axios
      .get(
        `/products/count/?&Deleted=0&${
          props.roleId === ADMIN_ROLE_ID ? '' : 'Buyable=true&'
        }`,
      )
      .then((res) => {
        setProducts(res.data);
      });

    axios
      .get(
        `/orders/count/?&${
          props.roleId === ADMIN_ROLE_ID
            ? ``
            : `${
                props.roleId === DISTRICT_ROLE_ID
                  ? `&DistrictUserId.id=${props.userId}&`
                  : props.roleId === SCHOOL_ROLE_ID
                    ? `&SchoolUserId.id=${props.userId}&`
                    : ''
              }`
        }`,
      )
      .then((res) => {
        setOrders(res.data);
      });

    axios
      .get(
        `/order-guides/count/?Active=true&${
          props.roleId === ADMIN_ROLE_ID
            ? ''
            : `&DistrictUserId.id=${props.userId}`
        }&`,
      )
      .then((res) => {
        setOrderGuides(res.data);
      });
  }, [props, props.roleId]);

  const handleAddOrder = () => {
    if (
      !(props.me?.shippingAddress || '').trim() &&
      props.roleId !== roles.ADMIN_ROLE_ID
    ) {
      notification.warning({
        message:
          'Please add your shipping address in your profile first, then proceed to place the order.',
      });
      return;
    }
    navigate('/orders/new');
  };

  const renderNews = () => {
    return (
      <div className="newsCard flex">
        <div className="text">
          <div className="title">NEWS</div>
        </div>
        <div className="icon">
          <ProfileOutlined style={{ fontSize: 24, color: '#97be12' }} />
        </div>
        <div class="break" />
        <div
          dangerouslySetInnerHTML={{
            __html: newsValue?.longValue || '<B>N/A</B>',
          }}
        />
      </div>
    );
  };
  const AdminDashboard = () => (
    <div className="flex stack-on-tablet" style={{ direction: 'column' }}>
      <div>
        <div className="flex">
          <Link to="/users">
            <div className="card flex">
              <div className="text">
                <div className="number">{districts ? districts : '...'}</div>
                <div className="title">Districts</div>
              </div>
              <div className="icon">
                <img src="/vectors/dud.png" alt="" />
              </div>
            </div>
          </Link>

          <Link to="/users/schools">
            <div className="card flex">
              <div className="text">
                <div className="number">{schools ? schools : '...'}</div>
                <div className="title">Schools</div>
              </div>
              <div className="icon">
                <img src="/vectors/sud.png" alt="" />
              </div>
            </div>
          </Link>
        </div>

        <div className="flex">
          <Link to={'/products'}>
            <div className="photo-card">
              <div className="card flex">
                <div className="text">
                  <div className="number">{products ? products : '...'}</div>
                  <div className="title">Products</div>
                </div>
                <div className="icon">
                  <img src="/vectors/pd.png" alt="" />
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="flex">
          <Link to={'/orders'}>
            <div className="card flex">
              <div className="text">
                <div className="number">{orders ? orders : '...'}</div>
                <div className="title">Orders</div>
              </div>
              <div className="icon">
                <img src="/vectors/od.png" alt="" />
              </div>
            </div>
          </Link>

          <Link to={'/order-guides'}>
            <div className="card flex">
              <div className="text">
                <div className="number">
                  {orderGuides ? orderGuides : '...'}
                </div>
                <div className="title">Order Guides</div>
              </div>
              <div className="icon">
                <img src="/vectors/ogd.png" alt="" />
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div style={{ flexBasis: '100%' }}>
        <div className="flex">{renderNews()}</div>
      </div>
    </div>
  );

  const DistrictDashboard = () => (
    <div className="flex" style={{ direction: 'column' }}>
      <div>
        <div className="flex">
          <Link to={'/products'}>
            <div className="photo-card">
              <div className="card flex">
                <div className="text">
                  <div className="number">{products ? products : '...'}</div>
                  <div className="title">Products</div>
                </div>
                <div className="icon">
                  <img src="/vectors/pd.png" alt="" />
                </div>
              </div>
            </div>
          </Link>

          <Link to={'/orders'}>
            <div className="new photo-card">
              <div className="card flex">
                <div className="text">
                  {/* <div className="number">{products ? products : "..."}</div> */}
                  <div className="title">Order History:</div>
                </div>
                <div className="icon">
                  <img src="/vectors/ohd.png" alt="" />
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="flex">
          <Link to={'/orders'}>
            <div className="card flex">
              <div className="text">
                <div className="number">{orders ? orders : '...'}</div>
                <div className="title">Orders</div>
              </div>
              <div className="icon">
                <img src="/vectors/od.png" alt="" />
              </div>
            </div>
          </Link>

          <Link to={'/order-guides'}>
            <div className="card flex">
              <div className="text">
                <div className="number">
                  {orderGuides ? orderGuides : '...'}
                </div>
                <div className="title">Order Guides</div>
              </div>
              <div className="icon">
                <img src="/vectors/ogd.png" alt="" />
              </div>
            </div>
          </Link>
        </div>
        <div className="flex">
          <div className="add-order" onClick={handleAddOrder}>
            <img src="/vectors/plus.png" alt="" /> <span>Add New Order</span>
          </div>
        </div>
      </div>
      <div style={{ flexBasis: '100%' }}>
        <div className="flex">{renderNews()}</div>
      </div>
    </div>
  );

  const SchoolDashboard = () => (
    <div className="flex" style={{ direction: 'column' }}>
      <div>
        <div className="flex">
          <Link to={'/products'}>
            <div className="photo-card">
              <div className="card flex">
                <div className="text">
                  <div className="number">{products ? products : '...'}</div>
                  <div className="title">Products</div>
                </div>
                <div className="icon">
                  <img src="/vectors/pd.png" alt="" />
                </div>
              </div>
            </div>
          </Link>

          <Link to={'/orders'}>
            <div className="new photo-card">
              <div className="card flex">
                <div className="text">
                  {/* <div className="number">{products ? products : "..."}</div> */}
                  <div className="title">Order History:</div>
                </div>
                <div className="icon">
                  <img src="/vectors/ohd.png" alt="" />
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="flex">
          <Link to={'/orders'}>
            <div className="card flex">
              <div className="text">
                <div className="number">{orders ? orders : '...'}</div>
                <div className="title">Orders</div>
              </div>
              <div className="icon">
                <img src="/vectors/od.png" alt="" />
              </div>
            </div>
          </Link>
          <div className="add-order" onClick={handleAddOrder}>
            <img src="/vectors/plus.png" alt="" /> <span>Add New Order</span>
          </div>
        </div>
      </div>
      <div style={{ flexBasis: '100%' }}>
        <div className="flex">{renderNews()}</div>
      </div>
    </div>
  );

  return (
    <div className="dashboard">
      <h2 className="title">Dashboard</h2>

      <div className="content">
        {props.roleId === ADMIN_ROLE_ID && <AdminDashboard />}
        {props.roleId === DISTRICT_ROLE_ID && <DistrictDashboard />}
        {props.roleId === SCHOOL_ROLE_ID && <SchoolDashboard />}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    roleId: state.me.roleId,
    userId: state.me.id,
    me: state.me.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
